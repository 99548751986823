<template>
  <div id="calendrier" class="hide-for-small-only">
      <span class="status" :class="status">{{getStatusFormatted}}</span>
      <br>
      <span class="horair cal"><strong>{{getCalendarFormatted}}</strong></span>
  </div>
</template>

<script>

export default {
  name: 'hoursWidget',
  data() {
    return {
      calendar: [],
      status: ''
    };
  },
  props: {
    baseWebservice: {type: String, default: window.location.host.indexOf('localhost') === 0 ? 'http://www.preprod.citeco.fr/api/authenticated/horaires': null}, // TODO remove
    language: String,
  },
  beforeMount() {
    let todayFormatted = this.getTodayFormatted;
    fetch(
      this.baseWebservice + '/' + todayFormatted + '.json'
    )
    .then(rep => {
      rep.json()
      .then(r => {
        this.calendar = r;
        this.status = this.calendar[0].status
      })
    });
  },
  computed: {
    getTodayFormatted: function() {
      let today = new Date();
      return today.getFullYear() + '-' + today.toLocaleDateString('fr-FR', {month: '2-digit'}) + '-' + today.toLocaleDateString('fr-FR', {day: '2-digit'});
    },
    getStatusFormatted: function() {
      let formattedStatus = '';
      if(this.status)
      {
        formattedStatus =  this.status.toUpperCase() + ' TODAY';
      }
      return formattedStatus
    },
    getCalendarFormatted: function() {
      let formattedCalendar = '';
      this.calendar.forEach(function(element, index) {
        if(index === 0)
        {
          formattedCalendar = 'From ' + element.from + ' to ' + element.to;
        }
        else
        {
          formattedCalendar += ' and ' + element.from + ' to ' + element.to;
        }
      });

      return formattedCalendar;
    }
  }
}
</script>

<style>
#calendrier .status{
  font-weight:bold;
  color:#ff3300;
}
#calendrier .status.open{
  color:#62C647;
  }
</style>
